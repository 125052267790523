import {Card, CardActionArea, CardContent, CardMedia, Grid, Typography} from "@mui/material";

const NoProject = () => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                In Berceni
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Niciun proiect cetatenesc nu se desfasoara in acest moment.<br /><br />
                                Daca doresti sa demarezi un proiect digital de dezbatere/informare adresat autoritatilor, ne poti contacta la adresa <strong>contact@inberceni.ro</strong>
                            </Typography>
                            <Typography>Proiecte anterioare:</Typography>
                            <ul>
                                <li>Metrou pentru Berceni (~200 de intrebari)</li>
                                <li>Dezbatere privind solicitarea de revizuire a Autorizatiei de Mediu a Gropii de Gunoi de la Vidra (~400 de intrebari)</li>
                            </ul>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
    )
}

export default NoProject;